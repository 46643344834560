































































































import { debounce } from "lodash";
import { defineComponent, reactive, watch } from "@vue/composition-api";
import { isKonsulen, isResiden } from "@/apps/accounts/modules/store";
import KegiatanPendukungFM from "../models/kegiatanPendukungForm";
import { SaveContext } from "@/apps/core/models/formModel";
import { toUserDateFormat } from "@/apps/core/modules/utils/datetime";

export default defineComponent({
  name: "KegiatanPendukungListBox",
  props: {
    kegiatanPendukung: { type: Object, required: true },
    verifikasi: String,
  },
  components: {
    Avatar: () => import("@/apps/core/components/Avatar.vue"),
    ContextMenu: () => import("@/apps/core/components/ContextMenu.vue"),
    LulusTag: () => import("@/apps/core/components/LulusTag.vue"),
  },
  setup(props) {
    const form = new KegiatanPendukungFM(["verifikasi"], []);
    const formRef = reactive(form);
    const instance = formRef.instance;

    Object.assign(formRef.instance, props.kegiatanPendukung);
    Object.assign(formRef.initState, form.instance);
    watch(instance, () => {
      if (!formRef.validity.edited) {
        formRef.setEdited(true);
      }
    });

    const updateVerifikasi = debounce(() => {
      formRef.validate('verifikasi');
      if (formRef.getIsValid().value) formRef.save(SaveContext.Update, false);
    }, 1000);
    formRef.resetErrorMap();

    return {
      // Data
      errorMap: formRef.errorMap, // reactive
      instance,

      // Computed
      isKonsulen,
      isResiden,

      // Method
      toUserDateFormat,
      updateVerifikasi,
    };
  },
});
